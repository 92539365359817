<template>
  <div
    v-show="open"
    class="absolute right-0 top-0 h-screen w-screen overflow-y-auto bg-black p-8"
  >
    <div class="flex items-center justify-between">
      <NuxtLink
        to="/"
        @click="open = false"
      >
        <IconsNextIcon class="h-10 text-white " />
      </NuxtLink>
      <button
        class="flex items-center rounded-full bg-white p-5"
        @click="open = false"
      >
        <Icon
          name="mdi:close"
          class="flex h-6 w-6"
        />
      </button>
    </div>
    <nav class="my-8">
      <ul class="divide-gray divide-y text-white">
        <li
          v-for="item in menu"
          :key="item.text"
          @click="open = false"
        >
          <NuxtLink
            :to="item.url"
            class="flex w-full py-6 text-2xl font-medium"
          >
            {{ item.text }}
          </NuxtLink>
        </li>
      </ul>
    </nav>
    <UiButton
      :to="{name: 'open-sollicitatie'}"
      label="CV uploaden"
    />
  </div>
</template>

<script setup lang="ts">
export type Menu = { text: string; url: string }[];

defineProps<{
  menu: Menu
}>();

const open = defineModel<boolean>('open', {
  required: true,
});
</script>
