<template>
  <svg
    width="151"
    height="48"
    viewBox="0 0 151 48"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_44_803)">
      <path
        d="M0 32V48H16L32 40L16 32H0Z"
        fill="currentColor"
      />
      <path
        d="M32 0H16C7.16444 0 0 7.16444 0 16V24L16 32V16H32V48H48V16C48 7.16444 40.8356 0 32 0Z"
        fill="currentColor"
      />
      <path
        d="M84.3778 32.3467L68.2667 8H64V40H68.2667V15.1267L84.7222 40H88.6444V8H84.3778V32.3467Z"
        fill="currentColor"
      />
      <path
        d="M108.456 35.6489C107.438 36.2867 106.131 36.6067 104.536 36.6067C102.36 36.6067 100.687 35.9156 99.5289 34.5378C98.5223 33.3444 97.9556 31.7111 97.82 29.6378L114.289 29.6V28.0756C114.289 25.9556 113.878 24.1111 113.049 22.5444C112.224 20.9778 111.062 19.76 109.567 18.8867C108.071 18.0156 106.311 17.58 104.276 17.58C102.24 17.58 100.407 18.0578 98.8534 19.0178C97.3023 19.9756 96.0823 21.2956 95.1956 22.98C94.3089 24.6622 93.8667 26.6222 93.8667 28.8578C93.8667 31.0933 94.3178 33 95.2156 34.6689C96.1156 36.3378 97.3623 37.6444 98.96 38.5889C100.556 39.5311 102.387 40.0022 104.444 40.0022C107.089 40.0022 109.264 39.3778 110.978 38.1311C112.689 36.8844 113.804 35.1267 114.329 32.8622H110.496C110.149 34.0822 109.469 35.0133 108.451 35.6511L108.456 35.6489ZM100.769 21.9111C101.74 21.3156 102.909 21.0178 104.273 21.0178C106.073 21.0178 107.511 21.5556 108.584 22.6289C109.658 23.7022 110.193 25.1244 110.193 26.8956H97.9111C98.0311 26.0244 98.2356 25.2244 98.5467 24.5222C99.0578 23.3778 99.7934 22.5067 100.769 21.9111Z"
        fill="currentColor"
      />
      <path
        d="M131.371 17.5778L125.698 26.1133L119.884 17.5778H115.133L122.953 28.9422L115.509 40H119.862L125.438 31.6844L131.104 40H135.853L128.222 28.9422L136.044 17.5778H131.371Z"
        fill="currentColor"
      />
      <path
        d="M150.078 17.5778H145.898V11.5267H141.804V17.5778H137.626V21.0178H141.804V40H145.898V21.0178H150.078V17.5778Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_44_803">
        <rect
          width="150.078"
          height="48"
          fill="currentColor"
        />
      </clipPath>
    </defs>
  </svg>
</template>
