<template>
  <div class="container relative z-50">
    <div class="flex items-center justify-between py-8 lg:py-10 ">
      <NuxtLink to="/">
        <IconsNextIcon
          class="h-10 lg:h-12 "
          :class="type === 'dark' ? 'text-black' : 'text-white'"
        />
      </NuxtLink>
      <nav class="hidden lg:block">
        <ul class="flex h-full items-center gap-6">
          <li
            v-for="item in baseMenu"
            :key="item.url"
          >
            <NuxtLink
              :to="item.url"
              class="rounded-full p-4 text-lg font-medium leading-none transition-all duration-300 hover:bg-white hover:text-black"
              :class="type === 'dark' ? 'text-black' : 'text-white'"
            >
              {{ item.text }}
            </NuxtLink>
          </li>
          <li>
            <UiButton
              :to="{name: 'open-sollicitatie'}"
              label="CV uploaden"
            />
          </li>
        </ul>
      </nav>
      <nav
        class="block lg:hidden"
      >
        <button
          class="flex items-center rounded-full bg-white p-5"
          @click="isMobileMenuOpen = true"
        >
          <Icon
            name="mdi:menu"
            class="flex h-6 w-6"
          />
        </button>
      </nav>
      <Transition>
        <AppMobileMenu
          v-model:open="isMobileMenuOpen"
          :menu="baseMenu"
          class="z-[9999]"
        />
      </Transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import {useScrollLock} from '@vueuse/core';
import type {Menu} from '~/components/app/MobileMenu.vue';

defineProps<{
  type?: 'dark'
}>();

const baseMenu: Menu = [
  {text: 'Vacatures', url: '/vacatures'},
  {text: 'Voor opdrachtgevers', url: '/werkgevers'},
  {text: 'Over ons', url: '/over-ons'},
  {text: 'Contact', url: '/contact'},
];

const isMobileMenuOpen = ref(false);

onMounted(() => {
  const isLocked = useScrollLock(document.body);

  watch(isMobileMenuOpen, (newValue) => {
    isLocked.value = newValue;
  });
});
</script>


<style scoped>
.v-enter-active,
.v-leave-active {
  transform: translateX(0%);
  transition: all 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  transform: translateX(-100%);
}

</style>

