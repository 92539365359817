<template>
  <footer class="bg-neutral-900 text-white">
    <div class="container py-12 lg:py-32">
      <div class="grid grid-cols-4 gap-y-12 text-lg font-medium md:auto-cols-min md:grid-cols-12 md:gap-10">
        <div class="col-span-4 flex flex-col gap-10 md:col-span-6 xl:col-span-8 ">
          <div class="flex items-center gap-6">
            <div>
              <img
                src="~/assets/img/next-recruitment-beeldmerk-black.svg"
                class="h-16 w-16 invert"
                alt="Placeholder"
              >
            </div>
            <p>
              {{ settings.address }} <br> {{ settings.postalcode }} {{ settings.city }}
            </p>
          </div>
          <ul class="flex flex-col gap-4 xl:flex-row">
            <li>
              <NuxtLink
                class="text-medium flex w-fit items-center gap-2 rounded-full border border-white p-6 text-lg leading-none"
                :to="`tel:${settings.phone}`"
              >
                <Icon
                  name="mdi:phone-outline"
                  class="h-6 w-6"
                />
                {{ settings.phone }}
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                class="text-medium flex w-fit items-center gap-2 rounded-full border border-white p-6 text-lg leading-none"
                :to="`mailto:${settings.email}`"
              >
                <Icon
                  name="mdi:email-outline"
                  class="h-6 w-6"
                />
                {{ settings.email }}
              </NuxtLink>
            </li>
          </ul>
        </div>
        <nav class="col-span-6 md:justify-self-end xl:col-span-4">
          <ul class="grid grid-cols-2 gap-10 ">
            <li
              v-for="item in menus.footer"
              :key="item?.url"
            >
              <NuxtLink
                :to="item?.url"
              >
                {{ item?.label }}
              </NuxtLink>
            </li>
          </ul>
        </nav>
        <div class="col-span-4 flex gap-8 md:col-span-12">
          <NuxtLink :to="settings.linkedin">
            <Icon
              name="mdi:linkedin"
              class="h-8 w-8"
            />
          </NuxtLink>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
const {menus} = useSofieMenus();
const {settings} = useSofieSettings();
</script>
